
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY, USER_ID } from '@/services/StorageService'
import { DataEmitterService } from '@/services/DataEmiterService'

export default {

  components: {
  },
  data() {
    return {
      showLegend: false,
      currentApplication: null,
      subPage: 'candidateList',
      search: '',
      items: [],
      page: 1,
      totalPages: 0,
      totalElements: 0,
      itemsPerPage: 10,
      areaFilter: 'All',
      selectedCategory: 'All',
      categories: [ 'All' ],
      expandedDescription: false,
      expandedPdf: false,
      expanded3D: false,
      expandedPersonalData: false,
      expandedTeamData: false,
      filterOptions: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Lighting',
          value: 'Lighting',
        },
        {
          label: 'Products customization',
          value: 'ProductsCustomization',
        },
        {
          label: 'Modular systems',
          value: 'ModularSystems',
        },
        {
          label: 'Artworks',
          value: 'Artworks',
        },
      ]
    }
  },

  computed: {
    pages() {
      return [
        this.page - 2,
        this.page - 1,
        this.page,
        this.page + 1,
        this.page + 2,
      ].filter(page => page >= 1 && page <= this.totalPages)
        .sort((a, b) => Math.abs(a - this.page) - Math.abs(b - this.page))
        .slice(0, 3)
        .sort((a, b) => a - b)
    },
  },

  watch: {

  },
  beforeRouteLeave(to, from, next) {
    next()
  },

  async mounted() {
   await this.updateItems()
    await this.getCategories()
  },

  methods: {
    memberText(index) {
      return [
        'First member',
        'Second member',
        'Third member',
        'Fourth member',
        'Fifth member',
        'Sixth member',
        'Seventh member',
        'Eighth member',
        'Ninth member',
        'Tenth member',
      ][index]
    },

    areaText(area) {
      return {
        'Lighting': 'Lighting',
        'ProductsCustomization': 'Products customization',
        'ModularSystems': 'Modular systems',
        'Artworks': 'Artworks',
      }[area]
    },

    updateAreaFilter(filter) {
      this.areaFilter = filter
      this.updateItems()
    },

    openLegend() {
      this.showLegend = true
      setTimeout(() => this.$refs.legend?.focus())
    },

    updateSearchText(text) {
      this.search = text
      this.updateItems()
    },

    updateItemsPerPage(value) {
      this.updateItems()
    },

    updatePage(page) {
      this.page = page
      this.updateItems()
    },

    openApplication(application) {
      this.currentApplication = application
      this.subPage = 'projectEvaluation'
      this.expanded3D = false
      this.expandedDescription = false
      this.expandedPdf = false
      this.expandedPersonalData = false
      this.expandedTeamData = false
    },

    async reset() {
      const data = await ApiRequestService.postRequest(`api/admin-user-competition/reset/${this.currentApplication.id}`, {})

      this.currentApplication = data
      await this.updateItems()
    },

    async toggleApprove(value) {
      if (this.currentApplication.evaluated) {
        value = !this.currentApplication.approved
      }

      const data = await ApiRequestService.postRequest(`api/admin-user-competition/approve/${this.currentApplication.id}`, {
        approved: value
      })

      this.currentApplication = data
      await this.updateItems()
    },

    async updateItems() {
      try {
        let url = `api/admin-user-competition?sortBy=id&direction=DESC&count=${this.itemsPerPage}&page=${this.page}`

        if (this.search) {
          url += `&searchText=${encodeURIComponent(this.search)}`
        }

        if (this.areaFilter !== 'All') {
          url += `&area=${encodeURIComponent(this.areaFilter)}`
        }

        if (this.selectedCategory) {
          //url += `&area=${encodeURIComponent(this.selectedCategory)}`
        }

        const data = await ApiRequestService.getRequest(url)

        this.items = data?.data ?? []
        this.totalElements = data?.totalElements ?? 0
        this.totalPages = data?.totalPages ?? 0
        this.page = data?.currentPage ?? 1

        if (this.page > this.totalPages) {
          this.page = this.totalPages

          if (this.page) {
            await this.updateItems()
          }
        }

      } finally {
        DataEmitterService.$emit( 'showLoader', false );
      }
    },

    async updateCategory(category) {
      this.selectedCategory = category;
      await this.updateItems();
    },
    async getCategories() {
      let url = `api/admin-user-competition/area`;
      const categories = await ApiRequestService.getRequest(url);
      if(categories && categories?.length){
        this.categories = this.categories.concat(categories);
      }
      this.selectedCategory = this.categories[0];
    }
  },
}

